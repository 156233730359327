import React, { useContext } from "react";
import { TranslationContext } from '../../translation/translationContext';
import { PRICE_BLUE } from '../../assets/colors';

const styles = {
    titleText: {
      fontFamily: 'roboto',
      fontWeight: 700,
      fontSize: 45,
      alignSelf: 'flex-start',
      marginStart: 60,
    },
    membershipContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 150,
      width: '70%'
    },
    membershipInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingLeft: 48,
      paddingRight: 48,
      paddingTop: 24,
      paddingBottom: 24,
      width: '100%',
      "box-shadow": '2px 2px 18px rgba(0, 0, 0, 0.25)'
    },
    leftInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      whiteSpace: 'pre-wrap'
    },
    rightInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    includesTitle: {
      fontFamily: 'roboto',
      fontWeight: 700,
      fontSize: 20
    },
    includesDescription: {
      fontFamily: 'roboto',
      fontWeight: 400,
      fontSize: 20,
      'line-height': '200%'
    },
    costTitle: {
      fontFamily: 'roboto',
      fontWeight: 400,
      fontSize: 20
    },
    costSubTitle: {
      fontFamily: 'roboto',
      fontWeight: 700,
      fontSize: 20,
      color: PRICE_BLUE,
      marginLeft: 24
    }
  };

const MembershipDescription = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <div id="fees" style={styles.membershipContainer}>
        <h1 style={styles.titleText}>{translate('feesMemberships')}</h1>
        <div boxShadow={3} style={styles.membershipInfoContainer}>
            <div style={styles.leftInfoContainer}>
                <p style={styles.includesTitle}>{`${translate('whatIsIncluded')}:`}</p>
                <p style={styles.includesDescription}>{translate('includedDescription')}</p>
            </div>
            <div style={styles.rightInfoContainer}>
                <h2 style={styles.costTitle}>{translate('costUpTo')}</h2>
                <h2 style={styles.costSubTitle}>{translate('costUpToPerYear')}</h2>
                <h2 style={styles.costTitle}>{translate('costAbove')}</h2>
                <h2 style={styles.costSubTitle}>{translate('costAbovePerYear')}</h2>
            </div>
        </div>
    </div>
  );
}

export default MembershipDescription;