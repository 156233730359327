import React, { useContext } from "react";
import { navigate } from "gatsby";
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/outsideNavbar';
import { VERY_LIGHT_BLUE } from '../assets/colors';
import { outsideNavbarHeight } from '../assets/dimensions';
import office from '../assets/images/office.svg';
import quote from '../assets/images/quote.svg';
import TeamDescription from '../components/views/teamDescription';
import MembershipDescription from '../components/views/membershipDescription';
import StandardButton from '../components/buttons/standardButton';
import ScrollButton from '../components/buttons/scrollButton';

const styles = {
  container: {
    backgroundColor: VERY_LIGHT_BLUE,
  },
  title: {
    marginTop: outsideNavbarHeight + 48,
    alignSelf: 'flex-start',
    marginLeft: '10%',
    marginRight: '10%',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 45,
    color: '#151E6A',
  },
  description: {
    alignSelf: 'flex-start',
    marginLeft: '15%',
    marginRight: '15%',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 20,
    'line-height': '150%',
    'white-space': 'pre-wrap'
  },
  image: {
    marginTop: 50,
    marginLeft: 48,
    marginRight: 48
  },
  contactButton: {
    marginTop: 150
  },
  scrollButton: {
    marginTop: 50,
    marginBottom: 50
  }
};

const AboutPage = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <Screen style={styles.container}>
        <Navbar currentTab={'/about'} />
        <h1 style={{...styles.title, ...styles.titleText}}>{translate('aboutUsTitle')}</h1>
        <p style={styles.description}>{translate('aboutUsDescription')}</p>
        <img style={styles.image} src={office} alt="office" />
        <img style={styles.image} src={quote} alt="quote" />
        <TeamDescription />
        <MembershipDescription />
        <StandardButton style={styles.contactButton} text={translate('contactUsToday')} onClick={() => navigate('/#contacts')} />
        <ScrollButton style={styles.scrollButton} />
    </Screen>
  );
};

export default AboutPage;
