import React, { useContext } from "react";
import { TranslationContext } from '../../translation/translationContext';
import missingPhoto from '../../assets/images/missing-photo.svg';
import stefanPhoto from '../../assets/images/stefan.jpg';
import annePhoto from '../../assets/images/anne.jpg';
import lenaPhoto from '../../assets/images/lena.jpg';

const styles = {
    titleText: {
      fontFamily: 'roboto',
      fontWeight: 700,
      fontSize: 45
    },
    teamContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 150,
      width: '70%'
    },
    teamTitle: {
      marginRight: 60,
    },
    topTeamContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: 60
    },
    fullMemberContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: 48,
      marginRight: 48
    },
    bottomMemberContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: 96,
      marginTop: 60
    },
    teamImage: {
      width: 450,
      height: 450,
      marginRight: 48
    },
    teamMemberContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: '40%'
    },
    teamMemberTitle: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 35,
      marginBottom: 32
    },
    teamMemberDescription: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 17,
      'line-height': '200%'
    }
  };

const TeamDescription = () => {
  const { translate } = useContext(TranslationContext);

  return (
    <div style={styles.teamContainer}>
        <div style={styles.topTeamContainer}>
            <h1 style={{...styles.titleText, ...styles.teamTitle}}>{translate('meetTheTeam')}</h1>
            <div style={styles.fullMemberContainer}>
                <img style={styles.teamImage} src={annePhoto} alt="Anne Broström" />
                <div style={styles.teamMemberContainer}>
                    <h1 style={styles.teamMemberTitle}>ANNE BROSTRÖM</h1>
                    <p style={styles.teamMemberDescription}>{translate('anneDescription')}</p>
                </div>
            </div>
        </div>
        <div style={styles.fullMemberContainer}>
            <img style={styles.teamImage} src={stefanPhoto} alt="Stefan Posner"  />
            <div style={styles.teamMemberContainer}>
                <h1 style={styles.teamMemberTitle}>STEFAN POSNER</h1>
                <p style={styles.teamMemberDescription}>{translate('stefanDescription')}</p>
            </div>
        </div>
        {/* <div style={styles.bottomMemberContainer}>
            <img style={styles.teamImage} src={lenaPhoto} alt="Lena Läckberg"  />
            <div style={styles.teamMemberContainer}>
                <h1 style={styles.teamMemberTitle}>LENA LÄCKBERG</h1>
                <p style={styles.teamMemberDescription}>{translate('lenaDescription')}</p>
            </div>
        </div> */}
    </div>
  );
};

export default TeamDescription;

